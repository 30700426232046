<script>

import Icon from "../../Icon";
import DefaultCard_1Header from "../default/DefaultCard_1Header";
import DefaultCard_2 from "../default/DefaultCard_2";
import SmokeCardDf_1AbstractChart from "./SmokeCardDf_1AbstractChart";
import SmokeCard_2LatestValue from "./SmokeCardDf_2LatestValue";
import SmokeCard_2MetricsButtons from "./SmokeCardDf_2MetricsButtons";

export default {
    name: "SmokeCardDf_2",
    extends: DefaultCard_2,
    components: {
        HeaderComponent: DefaultCard_1Header,
        MetricsButtonsComponent: SmokeCard_2MetricsButtons,
        LatestValueComponent: SmokeCard_2LatestValue,
        ChartComponent: SmokeCardDf_1AbstractChart,
        Icon
    },
    computed: {
        firstMetric() {
            return this.deviceObject?.getType()?.excludeInternalMetrics()?.getMetricsWithPortsExclude(["bat_volt"])?.sort((a, b) => {
                return b.getSlug() === "smoke_status" ? 0 : -1;
            }).sort((a, b) => {
                return b.getSlug() === "bat_percent" ? -1 : 0
            })[0];
        },
        getPaginatedData() {
            const start = this.paginatedData.currentPage * this.paginatedData.perPage,
                end = start + this.paginatedData.perPage;
            return this.deviceObject?.getType()?.excludeInternalMetrics()?.getMetricsWithPortsExclude(["bat_volt"])?.sort((a, b) => {
                return b.getSlug() === "smoke_status" ? 0 : -1;
            }).sort((a, b) => {
                return b.getSlug() === "bat_percent" ? -1 : 0
            }).slice(start, end);
        },
        metricImage() {
            const deviceSlug = this.deviceObject?.getType()?.getSlug();
            const intValue = this.timeseries?.find(item => item.getSlug() === 'smoke_status')?.getLatestData()?.getValue();
            let iconFile;
            try {
                if (this.currentMetric.getSlug() === 'smoke_status') {
                    if (intValue === 0) {
                        iconFile = require(`../../../assets/metrics/all_ok.png`);
                    } else if (intValue === 1) {
                        iconFile = require(`../../../assets/metrics/fire.png`);
                    } else if (intValue === 8) {
                        iconFile = require(`../../../assets/metrics/test.png`);
                    }
                } else {
                    iconFile = require(`../../../assets/metrics/${this.currentMetric?.getIcon()}.png`);
                }

                return {
                    "background-image": `url(${iconFile})`
                }
            } catch (e) {
                return null;
            }
        },
    }

}
</script>
