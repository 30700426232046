<template>
    <div :style="{backgroundColor:  tableColor || '#e3e3e3' }" class="gerkon-statistic">
        <div v-for="(item, index) in slicedTimeseries" :key="index" class="mb-2 pl-1 row">
            <div class="text-nowrap  pl-1" style="width: 40%">
                <strong v-if="item.value === 0">{{ $t('Все ОК') }}</strong>
                <strong v-else-if="item.value === 1">{{ $t('Внимание! Дым!') }}</strong>
                <strong v-else-if="item.value === 8">{{ $t('Тестирование') }}</strong>
                <strong v-else>N/A</strong>
            </div>
            <div class="text-nowrap pl-1" style="width: 30%">
                <i class="fas fa-calendar-alt"></i>
                <strong> {{ item.date }}</strong>
            </div>
            <div class="text-nowrap pl-1" style="width: 30%">
                <i class="far fa-clock"></i>
                <strong> {{ item.time }}</strong>
            </div>
        </div>
    </div>
</template>

<script>


import moment from "moment-mini";
import config from "../../../config";

import DefaultCard_1Table from "@/components/deviceCards/default/DefaultCard_1Table.vue";

export default {
    name: "SmokeCardDf_1Table",

    extends: DefaultCard_1Table,

    computed: {
        slicedTimeseries() {

            return this.metricTimeseries?.slice(0, 6)?.map(item => {
                return {
                    date: moment(item.getDate()).format(config.dateTimeFormats.DDMMYYYY),
                    time: moment(item.getDate()).format(config.dateTimeFormats.HHmmss),
                    value: item?.getValue(),
                };
            });
        }
    },
}
</script>

<style scoped lang="less">

.gerkon-statistic {
    border-radius: 10px;
    height: 190px;
    padding: 1rem 1.5rem;
    font-size: 13px;
    color: #fff;

    &-item {
        margin: 5px 0;
    }

    &-icon {
        width: 20px;
        height: 20px;
        border-radius: 5px;
        background-color: white;
    }

    &-time {
        min-width: 80px;
        font-weight: 600;
        line-height: 14px;
    }
}
</style>
