<script>

import LineChart from "../CardLineChart";
import DefaultCard_1LineChart from "../default/DefaultCard_1Chart";
import DefaultCard_1AbstractChart from "../default/DefaultCard_1AbstractChart";
import SmokeCardDf_1Table from "@/components/deviceCards/smoke-df/SmokeCardDf_1Table.vue";

export default {
    name: "SmokeCardDf_1AbstractChart",
    extends: DefaultCard_1AbstractChart,
    components: {
        DefaultCard_1Table: SmokeCardDf_1Table,
        DefaultCard_1LineChart,
        LineChart
    },
}
</script>
