<template>
    <div class="row mb-2" style="min-height: 140px">
        <div class="col-12 position-relative">
            <div class="list-group">
                <div
                    class="list-group-item px-2 list-group-item-action cursor-pointer font-weight-bold justify-content-between align-items-baseline"
                    v-for="(metric, index) in paginatedData"
                    @click="$emit('change', metric)"
                    :key="index"
                    :class="{ active : isSelected(metric) }"
                >
                    <div class="d-flex justify-content-center align-items-baseline">
                        <div class="pr-2">
                            <Icon icon-name="all_ok"
                                  v-if="metric.getSlug() === 'smoke_status' && +getMetricLabel(metric) === 0"
                                  width="24"
                                  fill="#3A9D44"
                                  height="24"
                                  viewBox="0 0 26 26"/>
                            <Icon icon-name="fire"
                                  v-else-if="metric.getSlug() === 'smoke_status' && +getMetricLabel(metric) === 1"
                                  width="23"
                                  height="23"
                                  fill="#FD7B32"
                            />
                            <Icon icon-name="test"
                                  v-else-if="metric.getSlug() === 'smoke_status' && +getMetricLabel(metric) === 8"
                                  width="23"
                                  height="23"
                                  fill="#03204A"
                            />
                            <Icon icon-name="bat_volt"
                                  v-else-if="metric.getSlug() === 'bat_percent'"
                                  width="23"
                                  height="23"
                                  :fill="metric.getColor()"
                            />
                            <Icon :icon-name="metric.getIcon()"
                                  v-else
                                  width="23"
                                  height="23"
                                  :fill="metric.getColor()"
                            />
                            <Icon v-if="isSelected(metric)" class="d-block mx-auto mt-1"
                                  icon-name="dot"
                                  :fill="isSelected(metric) ? metric.getColor() : ''"
                                  width="4"
                                  height="4"/>

                            <div v-else class="mt-1" style="height: 4px"></div>
                        </div>
                        <div v-if="metric.getName().length > 20"
                             class="list-group-item-title"
                             data-toggle="tooltip"
                             :title="metric.getName()">
                            {{ stringLimit(metric.getName(), 20) }}
                        </div>
                        <div v-else class="list-group-item-title">
                            {{ metric.getName() }}
                        </div>
                    </div>
                    <div class="list-group-item-value">
                        <div v-if="metric.getSlug() === 'smoke_status' && getLatestStatusValue(metric) !== null">
                            <div class="d-flex align-items-center">
                                <div>
                                    {{
                                        getLatestStatusValue(metric) === 0 ? $t('Все ОК') : getLatestStatusValue(metric) === 1 ? $t('Внимание! Дым!') : $t('Тестирование')
                                    }}
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            {{ getMetricLabel(metric, 0) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Icon from "../../Icon";
import DefaultCard_3ValuesList from "../default/DefaultCard_3ValuesList";

export default {
    name: "SmokeCardDf_3ValuesList",
    extends: DefaultCard_3ValuesList,
    components: {
        Icon,
    },
    methods: {
        getLatestStatusValue() {
            const value = this.timeseries
                ?.find(item => item.getSlug() === "smoke_status")
                ?.getLatestData()
                ?.getValue();

            return parseInt(value);
        },
    }
}
</script>

<style lang="less" scoped>

@import "../../../less/_variables";

.list-group {
    border-radius: 0;

    &-item {
        display: flex;
        border-color: transparent;
        padding: 0.45rem 1.25rem;
        font-size: 0.9rem;
        border-radius: .5rem;

        &:hover {
            background: white;
        }

        &-title {
            font-weight: 500;
        }

        &-value {
            font-weight: 600;
            color: #706C6C;
        }

        &.active {
            color: #495057;
            background: #f1f1f1;
        }

        .status-icon {
            background: #FFFFFF;
            border-radius: 3px;
            width: 30px;
            height: 30px;
        }
    }
}

</style>
