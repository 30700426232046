<script>

import Icon from "../../Icon";
import DefaultCard_1MetricsButtons from "../default/DefaultCard_1MetricsButtons";
import SmokeCard_1LatestValue from "./SmokeCardDf_1LatestValue";
import SmokeCard_1Button from "./SmokeCardDf_1Button";
import DefaultCard_1Header from "../default/DefaultCard_1Header";
import DefaultCard_1 from "../default/DefaultCard_1";
import SmokeCardDf_1AbstractChart from "./SmokeCardDf_1AbstractChart";

export default {
    name: "SmokeCardDf_1",
    extends: DefaultCard_1,
    components: {
        HeaderComponent: DefaultCard_1Header,
        MetricsButtonsComponent: SmokeCard_1Button,
        LatestValueComponent: SmokeCard_1LatestValue,
        ChartComponent: SmokeCardDf_1AbstractChart,
        Icon
    },
    computed: {
        firstMetric() {
            return this.deviceObject?.getType()?.excludeInternalMetrics()?.getMetricsWithPortsExclude(["bat_volt"])?.sort((a, b) => {
                return b.getSlug() === "smoke_status" ? 0 : -1;
            }).sort((a, b) => {
                return b.getSlug() === "bat_percent" ? -1 : 0
            })[0];
        },
        getPaginatedData() {
            const start = this.paginatedData.currentPage * this.paginatedData.perPage,
                end = start + this.paginatedData.perPage;
            return this.deviceObject?.getType()?.excludeInternalMetrics()?.getMetricsWithPortsExclude(["bat_volt"])?.sort((a, b) => {
                return b.getSlug() === "smoke_status" ? 0 : -1;
            }).sort((a, b) => {
                return b.getSlug() === "bat_percent" ? -1 : 0
            }).slice(start, end);
        },
    }
}
</script>
