<script>

import Icon from "../../Icon";
import DefaultCard_1Header from "../default/DefaultCard_1Header";
import DefaultCard_3 from "../default/DefaultCard_3";
import SmokeCardDf_1AbstractChart from "./SmokeCardDf_1AbstractChart";
import SmokeCard_3ValuesList from "./SmokeCardDf_3ValuesList";

export default {
    name: "SmokeCard_3",
    extends: DefaultCard_3,
    components: {
        HeaderComponent: DefaultCard_1Header,
        ValuesListComponent: SmokeCard_3ValuesList,
        ChartComponent: SmokeCardDf_1AbstractChart,
        Icon
    },
    computed: {
        firstMetric() {
            return this.deviceObject?.getType()?.excludeInternalMetrics()?.getMetricsWithPortsExclude(["bat_volt"])?.sort((a, b) => {
                return b.getSlug() === "smoke_status" ? 0 : -1;
            }).sort((a, b) => {
                return b.getSlug() === "bat_percent" ? -1 : 0
            })[0];
        },
        getPaginatedData() {
            const start = this.paginatedData.currentPage * this.paginatedData.perPage,
                end = start + this.paginatedData.perPage;
            return this.deviceObject?.getType()?.excludeInternalMetrics()?.getMetricsWithPortsExclude(["bat_volt"])?.sort((a, b) => {
                return b.getSlug() === "smoke_status" ? 0 : -1;
            }).sort((a, b) => {
                return b.getSlug() === "bat_percent" ? -1 : 0
            }).slice(start, end);
        },
    }
}
</script>
