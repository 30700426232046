<template>
    <div>
        <div class="d-flex justify-content-around metric py-2">
            <div v-for="(metric, index) in paginatedData"
                 :key="index"
                 class="metric-buttons d-flex align-items-center justify-content-center flex-column"
                 :class="{ active : isSelected(metric) }"
                 @click="$emit('change', metric)">
                <Icon icon-name="all_ok"
                      v-if="metric.getSlug() === 'smoke_status' && +getMetricLabel(metric) === 0"
                      width="24"
                      fill="#3A9D44"
                      height="24"
                      viewBox="0 0 26 26"/>
                <Icon icon-name="fire"
                      v-else-if="metric.getSlug() === 'smoke_status' && +getMetricLabel(metric) === 1"
                      width="24"
                      height="24"
                      fill="#FD7B32"
                      viewBox="0 0 26 26"/>
                <Icon icon-name="test"
                      v-else-if="metric.getSlug() === 'smoke_status' && +getMetricLabel(metric) === 8"
                      width="24"
                      height="24"
                      fill="#03204A"
                      viewBox="0 0 26 26"/>
                <Icon icon-name="bat_volt"
                      v-else-if="metric.getSlug() === 'bat_percent'"
                      width="24"
                      height="24"
                      :fill="metric.getColor()"
                      viewBox="0 0 26 26"/>
                <Icon :icon-name="metric.getIcon()"
                      v-else
                      width="24"
                      height="24"
                      :fill="metric.getColor()"
                      viewBox="0 0 26 26"/>

                <div class="metric-data py-1"
                     :style="isSelected(metric) ? `color: ${iconColor(metric)}` : 'color: #706C6C'">
                    <span v-if="metric.getSlug() === 'smoke_status'">
                        <span v-if="intValue(metric) === 1">{{ $t('Внимание! Дым!') }}</span>
                        <span v-else-if="intValue(metric) === 0">{{ $t('Все ОК') }}</span>
                        <span v-else-if="intValue(metric) === 8">{{ $t('Тестирование') }}</span>
                        <span v-else>N/A</span>
                    </span>
                    <span v-else>
                        {{ getMetricLabel(metric, 0) }}
                    </span>
                </div>

                <Icon class="d-block mx-auto"
                      v-if="isSelected(metric)"
                      icon-name="dot"
                      :fill="iconColor(metric)"
                      width="7"
                      height="7"/>

                <div v-else style="height: 7px"></div>
            </div>
        </div>
    </div>
</template>

<script>

import DefaultCard_2MetricsButtons from "../default/DefaultCard_2MetricsButtons";

export default {
    name: "SmokeCardDf_2MetricsButtons",
    extends: DefaultCard_2MetricsButtons,
    data() {
        return {
            textStatus1: this.$t('Открыто'),
            textStatus0: this.$t('Закрыто'),
        }
    },
    methods: {
        intValue(metric) {
            const value = this.timeseries.find(item => item.getSlug() === metric?.getSlug())?.getLatestData()?.getValue();
            return parseInt(value);
        },
        iconColor(metric) {
            return metric.getSlug() === 'smoke_status' && +this.getMetricLabel(metric) === 0 ? '#3A9D44'
                : metric.getSlug() === 'smoke_status' && +this.getMetricLabel(metric) === 1 ? '#FD7B32'
                    : metric.getSlug() === 'smoke_status' && +this.getMetricLabel(metric) === 8 ? '#03204A'
                        : metric.getColor()
        }

    }
}
</script>
