<template>
    <div ref="latestValue" class="latest-value">
        <div v-if="value !== null" class="text-center">
            <div v-if="currentMetric.getSlug() === 'smoke_status'"
                 class="d-inline-flex align-items-center justify-content-center latest-value__status">
                <div class="text-success" v-if="intValue === 0">{{ $t('Все ОК') }}</div>
                <div class="text-danger" v-else-if="intValue === 1">{{ $t('Внимание! Дым!') }}</div>
                <div v-else-if="intValue === 8">{{ $t('Тестирование') }}</div>
            </div>
            <div v-else>
                <div class="latest-value__value">{{ Math.round(value) }}</div>
                <div class="latest-value__units">{{ units }}</div>
            </div>
        </div>
        <div v-else>
            <div class="latest-value__value">N/A</div>
            <div class="latest-value__units">{{ units }}</div>
        </div>
    </div>
</template>

<script>

import SmokeCard_1LatestValue from "./SmokeCardDf_1LatestValue";

export default {
    name: "SmokeCardDf_2LatestValue",
    extends: SmokeCard_1LatestValue,
}
</script>

<style lang="less" scoped>
.latest-value {
    width: 100%;
    padding: 10px 0 0;

    .status-icon {
        display: inline-block;
        background: #fff;
        padding: 5px 10px;
        border-radius: 5px;
    }

    &__bg {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: #ccc;
        color: #fff;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    &__value {
        text-align: center;
        font-weight: 700;
        font-size: 2rem;
        line-height: 1;
        color: #fff;
    }

    &__units {
        text-align: center;
        font-weight: 500;
        font-size: 1.2rem;
        color: #fff;
    }

    &__status {
        background: #FFFFFF;
        border-radius: 10px;
        height: 40px;
        text-align: center;
        padding: 0 10px;
        font-weight: 500;
        font-size: 13px;
        color: #61616A;
    }
}

</style>
